<script setup lang="ts">
import { useRouteQuery } from '@vueuse/router'
import 'swiper/css'
import 'swiper/css/autoplay'

import NewFooter from '~/components/organisms/NewFooter.vue'
import SubFooter from '~/components/atoms/SubFooter.vue'
import { fetchSubPages } from '~/composables/subPages'

const {
  locale,
  t,
} = useI18n()
const localePath = useLocalePath()
const { ecommerce } = useAnalytics()
const router = useRouter()
const route = useRoute()

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
})

const title = computed(() => t('layouts.title', {
  title: t(route.meta.title ?? 'pages.title.index'),
}))

const [resultActiveOrder, , resultCollections, , resultSubPages] = await Promise.all([
  fetchActiveOrder(),
  fetchActiveChannel(),
  fetchTopLevelCollections(),
  fetchAvailableCountries(),
  fetchSubPages(),
])

const {
  data: activeOrder,
  loading: activeOrderLoading,
} = toRefs(resultActiveOrder)
const { data: collections } = toRefs(resultCollections)
const {
  data: subPages,
  loading: subPagesLoading,
} = toRefs(resultSubPages)
const runtimeConfig = useRuntimeConfig()

const companyData = runtimeConfig.public.company

const rootCollections = computed(() => collections.value?.filter(collection => collection.parent?.name === '__root_collection__') ?? [])

const drawers = useDrawers()

const searchQuery = useRouteQuery('q', '')
const inputSearchQuery = ref(searchQuery.value)

const isSearchPage = computed(() => ((route?.name as string) ?? '').includes('search'))

async function handleCartClick() {
  drawers.value.cart.open = true
  ecommerce.viewCart()
}

function handleNavigationClick() {
  drawers.value.navigation.open = true
}

function submitSearch(event: Event) {
  event.preventDefault()
  router.push({
    path: localePath('/search'),
    query: {
      q: inputSearchQuery.value,
    },
  })
}

watch(isSearchPage, (isSearchPage) => {
  if (!isSearchPage)
    inputSearchQuery.value = ''
})

const days = computed(() => {
  const {
    0: monday,
    4: firday,
  } = getWeekdays(locale.value)
  return `${monday} - ${firday}`
})
const hours = computed(() => {
  const {
    6: six,
    18: eighteen,
  } = getHours(locale.value)
  return `${six} - ${eighteen}`
})
const verticalFeatures = [
  {
    title: t('trustable.best_ecommerce'),
    description: t('trustable.more_than_197_K_satisfied_customers'),
    icon: '/icons/award.svg',
  },
  {
    title: t('trustable.fast_shipping'),
    description: t('trustable.for_all_orders'),
    icon: '/icons/box.svg',
  },
  {
    title: t('trustable.will_be_send_today'),
    description: t('trustable.whatever_you_want_we_can_send_today'),
    icon: '/icons/rocket.svg',
  },
  {
    title: t('trustable.100_days_to_return'),
    description: t('trustable.if_you_dont_like_you_can_return_it'),
    icon: '/icons/filled-return-box.svg',
  },
]

const navbarRef = ref<HTMLElement | null>(null)
const { width: navbarWidth } = useElementSize(navbarRef)

const offscreenElements = ref<HTMLElement[]>([])

const visibleCategories = ref([])
const isCheckout = computed(() => typeof route.name === 'string' && route.name.includes('checkout'))
const test = false
function computeVisibleCategories() {
  visibleCategories.value = []

  let filledWidth = 0
  for (let i = 0; i < rootCollections.value.length; i++) {
    const category = rootCollections.value[i]
    const estimatedWidth = offscreenElements.value[i]?.offsetWidth + 32 // include margin

    if (filledWidth + estimatedWidth > navbarWidth.value)
      break

    filledWidth += estimatedWidth
    visibleCategories.value.push(category)
  }
}

watchEffect(computeVisibleCategories)

onMounted(fetchChildCollections)
</script>

<template>
  <div>
    <Html :lang="head.htmlAttrs!.lang" :dir="head.htmlAttrs!.dir" class="scroll-smooth">
      <Head>
        <Title>{{ title }}</Title>
        <Link
          v-for="link in head.link" :id="link.id" :key="link.id" :rel="link.rel" :href="link.href"
          :hreflang="link.hreflang"
        />
        <Meta v-for="meta in head.meta" :id="meta.id" :key="meta.id" :property="meta.property" :content="meta.content" />
        <Meta
          v-if="$config.public.facebookDomainVerification" name="facebook-domain-verification"
          :content="$config.public.facebookDomainVerification"
        />
      </Head>
      <Body class="font-sans antialiased">
        <div data-scroll>
          <!--      <TopBar class="bg-[conic-gradient(at_top_right,_var(&#45;&#45;tw-gradient-stops))] from-rose-500 via-red-500 to-orange-500 font-medium text-white"> -->
          <div>
            <div />
          </div>
          <TopBar
            v-if="test"
            class="bg-[#d10239] text-white font-medium lg:block"
          >
            <!--  <template #left>
  <div class="flex items-center justify-start">
    <TopbarSupport />
  </div>
</template> -->
            <!--    <template #right>
  <ContentGuard>
    <div class="ml-1 flex items-center">
      <p class="inline-block text-sm font-bold">
        {{ $t('trustable.free_shipping_and_returns') }} | {{ $t('trustable.days_return') }}
      </p>č
    </div>
  </ContentGuard>
</template> -->

            <template #center>
              <!-- <ContentGuard>
    <Swiper
      class="h-full"
      direction="horizontal"
      :modules="[Autoplay]"
      :pagination="{ clickable: false }"
      :slides-per-view="1"
      :space-between="0"
      :autoplay="{ delay: 2500 }"
      loop
    >
      <SwiperSlide>
        <div class="h-full flex items-center justify-center text-center text-sm sm:text-base">
          ✨ {{ $t('trustable.more_than_orders') }} ✨
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div class="h-full flex items-center justify-center text-center text-sm sm:text-base">
          📞 {{ $t('trustable.infoline') }} <a :href="`tel:${companyData.phoneNumber}`">
            {{ companyData.phoneNumber }} 📞</a>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div class="h-full flex items-center justify-center text-center text-sm sm:text-base">
          ↩️ {{ $t('trustable.100_days_to_return') }} ↪️
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div class="h-full flex items-center justify-center text-center text-sm sm:text-base">
          🚀 {{ $t('trustable.ship_in_24_hours') }} 🚀
        </div>
      </SwiperSlide>
    </Swiper>
  </ContentGuard>
</template> ... -->
              <div class="flex items-center justify-center text-white">
                <div class="flex items-center gap-x-2">
                  <div class="bg-white p-x-3 p-y-0.5 text-black uppercase">
                    CAŁKOWITA
                    SPRZEDAŻ
                  </div>
                  <!-- <OfferCountdown>
                    <template #default="{ time }">
                      <div class="custom-time-display">
                        <span class="mx-0.5 bg-black p-x-1.5 py-0.5 text-white">{{ time.hours }}</span><span class="text-black">:</span>
                        <span class="mx-0.5 bg-black p-x-1.5 py-0.5 text-white">{{ time.minutes }}</span><span class="text-black">:</span>
                        <span class="mx-0.5 bg-black p-x-1.5 py-0.5 text-white">{{ time.seconds }}</span>
                      </div>
                    </template>
                  </OfferCountdown> -->
                </div>
              </div>
            </template>
          </TopBar>

          <Header>
            <template #logo>
              <div class="flex items-center gap-x-2">
                <div class="block">
                  <NuxtLink :to="localePath('/')" class="ml-0 mr-3 flex overflow-hidden md:w-[6.75rem] md:w-auto">
                    <StoreLogo v-if="isCheckout" class="h-10 w-auto text-slate-900 dark:text-white" />
                  </NuxtLink>
                  <NButton
                    v-if="!isCheckout"
                    n="xl borderless"
                    icon="ph:list-bold"
                    class="p-0.25em text-black children:m-0 hover:text-primary-2"
                    @click="handleNavigationClick"
                  />
                </div>
                <div
                  v-if="!isCheckout"
                  class="hidden items-center gap-2 lg:flex"
                >
                  <NLink
                    :to="localePath('/collections/hot-sales')"
                    class="ml-0 flex overflow-hidden bg-black px-3 py-0.5 text-white font-bold uppercase no-underline md:w-auto hover:text-white"
                  >
                    Bestsellery
                  </NLink>
                  <NLink
                    :to="localePath('/collections/new-arrivals')"
                    class="ml-0 flex overflow-hidden no-underline md:w-auto"
                  >
                    Nowe produkty
                  </NLink>
                </div>
              </div>
            </template>

            <template v-if="!isCheckout" #center>
              <NuxtLink :to="localePath('/')" class="ml-0 mr-3 w-[6.75rem] flex overflow-hidden md:w-auto">
                <StoreLogo class="h-10 w-auto text-slate-900 dark:text-white" />
              </NuxtLink>
              <!-- todo move <form @submit.prevent="submitSearch">
    <NTextInput v-model="inputSearchQuery" icon="ph:magnifying-glass" />
  </form> -->
            </template>

            <template #right>
              <!--          <NButton n="xl" icon="heroicons:heart" class="p-0.25em children:m-0 border-0 shadow-none" /> -->
              <!-- <NButton
v-if="!isCheckout"
n="xl borderless"
icon="ph:user"
class="p0.25em pl0 text-white children:m0 md:mr-4 hover:text-white"
:to="localePath('/account')"
>
<template #text>
<span class="hidden text-xs font-bold uppercase md:visible">{{ $t('trustable.sign_in_register') }}</span>
</template>
</nbutton> -->
              <div class="relative flex items-center">
                <NButton
                  v-if="!isCheckout"
                  n="xl borderless"
                  icon="ph:magnifying-glass-light"
                  class="p-0.25em text-black children:m-0 hover:text-primary-2"
                  @click="handleNavigationClick"
                />
                <NButton
                  n="xl borderless"
                  icon="ph:shopping-cart-light"
                  class="p0.25em text-black opacity-100 children:m0 md:rounded-md md:bg-white md:p-x-4 md:p-y-2 hover:text-black md:text-black"
                  @click="handleCartClick"
                >
                  <template #text>
                    <div
                      v-if="activeOrder && activeOrder.totalQuantity && !activeOrderLoading"
                      class="hidden text-sm text-black font-bold md:block"
                    >
                      <Price :regular="activeOrder.subTotal">
                        <template #regular="{ regularPrice }">
                          <span class="font-bold">
                            {{ regularPrice }}
                          </span>
                        </template>
                      </Price>
                    </div>
                  </template>
                </NButton>
                <span
                  v-if="activeOrder && activeOrder.totalQuantity && !activeOrderLoading"
                  class="pointer-events-none absolute h6 w6 flex items-center justify-center rounded-full bg-primary-2 text-context -right-2 -top-1"
                  n="sm white"
                >
                  {{ activeOrder.totalQuantity }}
                </span>
              </div>
            </template>
            <template #navbar>
              <div class="shrink grow">
                <div
                  ref="navbarRef"
                  class="ml-2 flex content-center items-center justify-center lg:flex lg:px-6 lg:py-3 lg:space-x-6"
                >
                  <template v-for="(collection, index) in visibleCategories" :key="collection.id">
                    <NuxtLink
                      :to="localePath(`/collections/${collection.id}/${collection.slug}`)"
                      n="sm slate9 dark:slate2"
                      class="whitespace-nowrap text-context fw-medium n-transition"
                    >
                      {{ collection.name }}
                    </NuxtLink>
                  </template>
                  <div id="offscreen" style="position: absolute; left: -10000px; top: -10000px;">
                    <span v-for="collection in rootCollections" :key="collection.id" ref="offscreenElements">
                      {{ collection.name }}
                    </span>
                  </div>
                </div>
              </div>
            </template>
            <!--
<template #trust-element>
  <ContentGuard>
    <div class="mr8 w-fit lg:py-1">
      <div class="w-fit rounded-md bg-primary-7 p-x-3 p-y-2 text-xs text-white font-bold uppercase">
        {{ $t('trustable.free_shipping_for_all_orders') }}
      </div>
    </div>
  </ContentGuard>
</template>
-->

            <template #search>
              <div class="w-full">
                <form @submit.prevent="submitSearch">
                  <NTextInput
                    v-model="inputSearchQuery"
                    icon="ph:magnifying-glass"
                  />
                </form>
              </div>
            </template>
          </Header>

          <slot v-bind="{ activeOrder }" />

          <NavigationDrawer />
          <CartDrawer />
        </div>

        <div class="flex-1" />
        <!--    <ContentGuard>
          <div class="mb-6 bg-slate-50">
            <div class="mx-auto max-w-2xl px-4 py-5 pt-0 lg:max-w-[120rem] lg:px-8 lg:px8 md:max-w-4xl sm:px-6">
              <div class="grid grid-cols-1 mt-3 gap-2 md:grid-cols-4 sm:grid-cols-2">
                <ShopBenefitRow
                  v-for="verticalFeature in verticalFeatures"
                  :key="verticalFeature.title"
                  :title="verticalFeature.title"
                  :description="verticalFeature.description"
                  :icon="verticalFeature.icon"
                  vertical
                />
              </div>
            </div>
          </div>
        </ContentGuard> -->
        <ContentGuard>
          <StoreMascot />
        </ContentGuard>
        <!--  <ContentGuard>
<div class="mx-auto max-w-2xl px-4 py-8 lg:max-w-[88rem] lg:px-8 lg:px8 md:max-w-4xl sm:px-6">
<div class="grid grid-cols-1 mt-3 gap-2 md:grid-cols-4 sm:grid-cols-2">
<ShopBenefitRow
v-for="storeFeature in storeFeatures"
:key="storeFeature.title"
:title="storeFeature.title"
:description="storeFeature.description"
:icon="storeFeature.icon"
:vertical="false"
/>
</div>
</div>
</ContentGuard> -->
        <!--
<Footer v-if="pages?.length" :columns="3">
<FooterColumn>
<FooterCompanyData />
</FooterColumn>
<FooterColumn>
<li>
<h3 class="k-text-heading text-base font-semibold">
Užitečné odkazy
</h3>
</li>
<li
v-for="page in pages"
:key="page.id" :title="page.name"
class="!m-0.5"
>
<NuxtLink :to="localePath(`/pages/${page.slug}`)" class="text-base">
{{ page.name }}
</NuxtLink>
</li>
</FooterColumn>
<FooterColumn>
<div class="flex justify-center">
<img src="/icons/shop-of-the-year.svg" alt="Shop of the year" class="max-w-[140px]">
</div>
</FooterColumn>

<template #subfooter>
<div class="flex flex-wrap items-center justify-around gap-4 space-x-6">
<NLogo logo="stripe" n="2xl" />
<NLogo logo="payment-acceptance/visa" n="lg" class="text-visa dark:text-white" />
<NLogo logo="payment-acceptance/mastercard" n="3xl" />
<NLogo logo="payment-acceptance/amex" n="3xl" class="text-amex" />
<NLogo logo="payment-acceptance/dinersclub" n="2xl" />
<NLogo logo="payment-acceptance/discover" n="md" />
</div>
</template>
</Footer> -->
        <NewFooter>
          <template #prefooter>
            <!-- <AppCTA /> -->
          </template>
          <FooterColumn>
            <FooterCompanyData />
          </FooterColumn>
          <FooterColumn>
            <template v-for="subPage in subPages" :key="subPage.id">
              <div v-if="subPage.name === 'INFORMACJE PRAWNE'">
                <li>
                  <h3 class="k-text-heading text-base font-semibold">
                    {{ subPage.name }}
                  </h3>
                </li>
                <template v-for="showInSubPage in subPage.pages">
                  <li v-if="showInSubPage.show" :key="showInSubPage.id" :title="showInSubPage.page.name" class="!m-0.5">
                    <NuxtLink :to="localePath(`/pages/${showInSubPage.page.slug}`)" class="text-base">
                      {{ showInSubPage.page.name }}
                    </NuxtLink>
                  </li>
                </template>
              </div>
            </template>
          </FooterColumn>

          <!-- FooterColumn>
            <FooterReviews />
          </FooterColumn> -->

          <FooterColumn>
            <h3 class="k-text-heading text-base font-semibold uppercase">
              {{ t('trustable.store_shopping') }}
            </h3>
            <InlineWithIcon
              icon-code="ph:truck"
              :text="`${t('trustable.fast_shipping')} `"
            />
            <InlineWithIcon
              icon-code="ph:package"
              :text="`${t('trustable.express_24_shipping')} `"
            />
            <InlineWithIcon
              icon-code="ph:arrows-clockwise"
              :text="`${t('trustable.return_in_100_days')} `"
            />
            <InlineWithIcon
              icon-code="ph:gift"
              :text="`${t('trustable.gift_for_every_order')} `"
            />
            <InlineWithIcon
              icon-code="ph:arrow-counter-clockwise"
              :text="`${t('trustable.free_return_and_dispute')} `"
            />
            <!--   <InlineWithIcon
              icon-code="ph:trophy"
              :text="`${t('trustable.competition_for_prizez')} `"
            /> -->
            <InlineWithIcon
              icon-code="ph:lock"
              :text="`${t('trustable.secure_web')} `"
            />
            <InlineWithIcon
              icon-code="ph:users-three"
              :text="`${t('trustable.more_than_100000_satisfied_customers')} `"
            />
          </FooterColumn>

          <template v-for="subPage in subPages" :key="subPage.id">
            <FooterColumn v-if="subPage.pages.some((sp) => sp.show && subPage.name !== 'INFORMACJE PRAWNE')">
              <li>
                <h3 class="k-text-heading text-base font-semibold">
                  <span v-if="subPage.name === 'OSTATNÍ 2'">
                  &nbsp;
                  </span>

                  <span v-else>
                    {{ subPage.name }}
                  </span>
                </h3>
              </li>
              <template v-for="showInSubPage in subPage.pages">
                <li v-if="showInSubPage.show" :key="showInSubPage.id" :title="showInSubPage.page.name" class="!m-0.5">
                  <NuxtLink :to="localePath(`/pages/${showInSubPage.page.slug}`)" class="text-base">
                    {{ showInSubPage.page.name }}
                  </NuxtLink>
                </li>
              </template>
            </FooterColumn>
          </template>
          <template #subfooter>
            <SubFooter />
          </template>
        </NewFooter>
      </Body>
    </Html>
  </div>
</template>
