<script setup lang="ts">
const localePath = useLocalePath()
</script>

<template>
  <div class="mx-auto hidden max-w-2xl px-4 py-2 lg:max-w-[50rem] lg:px-8 lg:px8 md:max-w-4xl md:py-5 sm:px-6">
    <div class="mascot">
      <NuxtLink :to="localePath('/')">
        <img src="/mascot_diamido.svg" alt="Diamido macot">
      </NuxtLink>
    </div>
  </div>
</template>

<style scoped>

</style>
