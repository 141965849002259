<template>
  <div class="n-top-bar relative z-20 block">
    <div class="mx-auto h-10 md:max-w-7xl md:flex md:items-center md:justify-between">
      <div class="hidden lg:block lg:flex-1">
        <slot name="left" />
      </div>
      <div class="h-full w-full flex items-center justify-center">
        <slot name="center" />
      </div>
      <div class="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
        <slot name="right" />
      </div>
    </div>
  </div>
</template>
