<script setup lang="ts">
import { useRouteQuery } from '@vueuse/router'
import type { CollectionListItemFragment } from '#graphql-operations'

type CollectionListItemFragmentWithChildren = CollectionListItemFragment & { children: CollectionListItemFragmentWithChildren[] }

const router = useRouter()
const route = useRoute()
const localePath = useLocalePath()
const drawers = useDrawers()
const state = useStore()
const { t } = useI18n()
const isSearchPage = computed(() => ((route?.name as string) ?? '').includes('search'))

const searchQuery = useRouteQuery('q', '')
const inputSearchQuery = ref(searchQuery.value)
function submitSearch(event: Event) {
  event.preventDefault()
  router.push({
    path: localePath('/search'),
    query: {
      q: inputSearchQuery.value,
    },
  })
  handleClose()
}
watch(isSearchPage, (isSearchPage) => {
  if (!isSearchPage)
    inputSearchQuery.value = ''
})
const currentNode = ref<CollectionListItemFragmentWithChildren | undefined>(undefined)
const parentNodes = ref<CollectionListItemFragmentWithChildren[]>([])

function handleClose() {
  drawers.value.navigation.open = false
  currentNode.value = undefined
  parentNodes.value = []
}

function buildTree(collections?: CollectionListItemFragment[] | null) {
  if (collections == null)
    return []

  const lookup: { [key: string]: CollectionListItemFragmentWithChildren } = {}
  const rootNodes: CollectionListItemFragmentWithChildren[] = []

  for (const collection of collections)
    lookup[collection.id] = { ...collection, children: [] }

  for (const collection of collections) {
    if (collection.parent && collection.parent.name === '__root_collection__') {
      rootNodes.push(lookup[collection.id])
    } else if (collection.parent && collection.parent.id in lookup) {
      if (collection.id !== collection.parent.id)
        lookup[collection.parent.id].children.push(lookup[collection.id])
      else
        console.error(`Circular reference detected in collection with id ${collection.id}`)
    }
  }

  return rootNodes
}

const collections = computed(() => state.value.collections)

const collectionsTree = computed(() => buildTree(collections.value))

async function handleNodeClick(collection: CollectionListItemFragmentWithChildren) {
  if (collection.children && collection.children.length > 0) {
    if (currentNode.value)
      parentNodes.value = [...parentNodes.value, currentNode.value]

    currentNode.value = {
      ...collection,
      children: [
        {
          ...collection,
          name: t('general.show_all'),
          children: [],
          featuredAsset: null,
        },
        ...collection.children,
      ],
    }
  } else {
    handleClose()
    try {
      await router.push(localePath(`/collections/${collection.id}/${collection.slug}`))
    } catch (err) {
      console.error(`Navigation to collection ${collection.id} failed`, err)
    }
  }
}

function handleBackClick() {
  currentNode.value = parentNodes.value.length > 0 ? parentNodes.value.pop() : undefined
}
</script>

<template>
  <NDrawer title="Menu" slide-from="left" :show="drawers.navigation.open" closable @close="handleClose">
    <div class="mt-2">
      <div class="flow-root">
        <div class="mb8 flex items-center items-center justify-center bg-gray-100 -mx-2 -mt-2 sm:px-3 sm:-mx-3">
          <form class="block w-full py-4" @submit.prevent="submitSearch">
            <NTextInput
              v-model="inputSearchQuery"
              clas="pt2 block"
              icon="ph:magnifying-glass"
              :placeholder="`${t('general.search.label')}`"
            />
          </form>
        </div>

        <div v-if="currentNode" class="flex pb-6">
          <NuxtLink
            class="relative w-full flex cursor-pointer items-center gap-x-3 rounded-md px2 py3 text-sm text-gray-700 font-semibold leading-6 hover:bg-gray-50"
            href
            @click="handleBackClick"
          >
            <NIcon class="h-5 w-5 shrink-0 text-gray-400" icon="heroicons:chevron-left" />

            <h3 class="absolute inset-0 flex items-center justify-center text-base text-gray-900 font-semibold leading-6">
              {{ currentNode.name }}
            </h3>
          </NuxtLink>
        </div>

        <ul role="list" class="-my-6">
          <li v-for="(node, index) in (currentNode ? currentNode.children : collectionsTree)" :key="`${node.id}-${index}`">
            <NuxtLink
              class="w-full flex cursor-pointer items-center gap-x-3 rounded-md p2 text-left text-sm text-gray-700 font-semibold leading-6 hover:bg-gray-50"
              href="#"
              @click.prevent="handleNodeClick(node)"
            >
              <NImg
                v-if="node.featuredAsset"
                class="h8 w6 rd-md object-cover object-center"
                preset="thumbnail"
                :src="node.featuredAsset.preview"
                width="32"
                height="32"
              />

              {{ node.name }}

              <NIcon class="ml-auto h-5 w-5 shrink-0 text-gray-400" icon="heroicons:chevron-right" />
            </NuxtLink>
          </li>
        </ul>
      </div>
    </div>
  </NDrawer>
</template>
